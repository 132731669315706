<template>
  <nav>
    <div class="navigator-div">
        <img class="navigator-logo" src="./assets/KairoTech_Logo.png" draggable="false" style="pointer-events:none"/>
        <router-link class="navigator-button" to="/akari-ii" target="_blank" rel="noopener">
          <Button link>Akari II</Button>
        </router-link>
        
        <router-link class="navigator-button" to="/akari-evo" target="_blank" rel="noopener">
          <Button link>
            Akari
            <img src="./assets/EVO.png" style="height: 15px;margin-left: -5px;"/>
          </Button>
        </router-link>
        
        <Button class="navigator-button" @click="ToTutorial()" link>使用教程</Button>
        
        <Button class="navigator-button"  @click="ToControlCenter()" link><span class="pi pi-external-link"></span>控制中心</Button>
        <SplitButton class="navigator-buy" :model=items severity="info">
            <span class="pi pi-cart-minus"></span>
            <span>购买</span>
        </SplitButton>
    </div>
  </nav>
  <router-view/>
</template>

<script setup>
const items = [
{
    label: '前往淘宝',
    icon: 'pi pi-external-link',
    command: () => {
      window.location.href = 'https://m.tb.cn/h.g4X4qwtZJIldNNi';
    }
},
{
    label: '前往闲鱼',
    icon: 'pi pi-external-link',
    command: () => {
      window.location.href = 'https://h5.m.goofish.com/item?id=791066202337';
    }
}
];

const ToControlCenter = () => {
  window.location.href = 'https://control.kairotech.net'
};
const ToTutorial = () => {
  window.location.href = 'https://chestnut-branch-fd6.notion.site/Akari-8349fc9bbc3741cc8d3088158e9d5037'
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.navigator-div
{
    margin: 0px;
    height: 70px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-content: center;
    position: absolute;
    z-index: 1;
}

.navigator-logo
{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 40px;
    margin-right: 20px;
    height: 25px;
}

.navigator-button
{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px;
    color: white;
    font-size: 15px;
    background-color: rgba(0, 0, 0, 0);
}

.navigator-buy
{
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: 20px;
}
</style>
