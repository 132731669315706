<template>
  <div class="home">
    <Background/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Background from '@/components/Background.vue'; // @ is an alias to /src
import Footer from '@/components/Footer.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'HomeView',
  components: {
    Background
  },
});

</script>
